import * as React from "react"
import Arrow from './arrow'
import { useState } from "react"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import ReactMarkdown from "react-markdown"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from 'gatsby';
const Grid = ({ props }) => {
  const [activeModal, setActiveModal] = useState(false);
  const { scrollYProgress } = useViewportScroll()
  const scale = useTransform(scrollYProgress, [0, 1], [0.5, 1]);
  const OverlayModal = ({ modalData }) => {
    return (
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ delay: .45, duration: .45 }}
        className="video-overlay">
        <button
          onClick={() => setActiveModal(false)}
          className="close">Close</button>
        <div className="content">
          <h2>{modalData.title}</h2>
          {modalData?.video &&
            <video autoPlay={true} controls={true} style={{ width: '100%' }}>
              <source src={modalData.video} type="video/mp4" />
            </video>}
          {modalData?.youTube &&
            <div className="video-wrap">
              <iframe
                style={{ width: '100%' }} src={modalData.youTube} ></iframe>
            </div>}
        </div>
      </motion.div>
    )
  }
  return (
    <>
      {props.grid_content_one.blocks.map((block, index) =>
        <>
          {activeModal === index &&
            <OverlayModal modalData={block} />}
        </>
      )}
      <div className="grid-wrap">
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: .45, duration: .45 }}
          className={`grid-content`}>
          <div className="grid-item">
            <h2>{props.grid_content_one.grid_title}</h2>
            <div class="video-grid two-col">
              {props.grid_content_one.blocks.map((block, index) =>
                <motion.div key={index} className="list-item">
                  {block.Header !== '' &&
                    <>
                      <div className="video-thumb" onClick={() => setActiveModal(index)}>
                        <img src={block?.thumb?.publicURL} alt={block.title} />
                        <div className="playButton">
                          <svg viewBox="0 0 198.43 198.43">
                            <circle cx="99.21" cy="99.21" r="99.21" />
                            <path
                              d="M122.67,102.73l-36.86,21.28c-3.27,1.89-7.37-.47-7.37-4.25v-42.56c0-3.78,4.09-6.14,7.37-4.25l36.86,21.28c3.27,1.89,3.27,6.61,0,8.5Z" />
                          </svg>
                        </div>
                      </div>
                      <h3>{block.title}</h3>
                    </>
                  }
                </motion.div>
              )}
            </div>
          </div>
        </motion.div>
      </div></>
  )
}
export default Grid
